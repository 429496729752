<template>
  <div>
    <!-- Search and Action Buttons -->
    <div class="row justify-content-end align-items-end mb-3">
      <div class="col-auto">
        <button @click="refresh" class="btn btn-secondary">
          <i class="bi bi-arrow-repeat"></i>
          Actualiser
        </button>
      </div>
      <div class="col-auto">
        <button @click="print" class="btn btn-secondary">
          <i class="bi bi-printer"></i>
          Imprimer
        </button> 
      </div>
      <div class="col-auto">
        <button @click="createPresence" class="btn btn-secondary">
          <i class="bi bi-printer"></i>
          Presence
        </button>
      </div>
    </div>
    
    <!-- DataTable -->
    <vue-data-table 
      :headers="headers" 
      :data="academies" 
      :buttons="tableButtons" 
      :summableColumns="['totalPayments','amountToPay','nextPaymentAmount']" 
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      headers: [
        { label: "Référence", field: "reference" },
        { label: "Nom Complet", field: "fieldFullName" },
        { label: "Naissance", field: "fieldBirthDate" },
        { label: "Adresse", field: "fieldAddress" },
        { label: "Téléphone", field: "fieldPhone" },
        { label: "Fixe", field: "fieldHomePhone" },
        { label: "M à Payer", field: "amountToPay" },
        { label: "M.Payé", field: "totalPayments" },
        { label: "Prochaine Paiement", field: "nextPaymentDate" },
        { label: "M. Prochaine ", field: "nextPaymentAmount" },
      ],
      tableButtons: [
        {
          label: "",
          class: "text-success",
          icon: "bi bi-eye-fill",
          handler: (row) => this.$router.push({ name: 'customers-details', params: { reference: row.reference } })
        },
        {
          label: "",
          class: "text-secondary",
          icon: "bi bi-pencil-square",
          handler: (row) => this.$router.push({ name: 'customers-edit', params: { reference: row.reference } })
        },
        {
          label: "",
          class: "text-danger",
          icon: "bi bi-trash",
          handler: (row) => this.remove(row)
        },
        {
          label: "",
          class: "text-danger",
          icon: "bi bi-printer",
          handler: (row) => this.printRegistration(row.reference)
        },
        {
          label: "",
          class: "text-primary",
          icon: "bi bi-whatsapp",
          handler: (row) => this.sendWhatsAppMessage(row.fieldPhone, row.nextPaymentAmount, row.fieldFullName, row.nextPaymentDate)
        }
      ]
    };
  },
  computed: {
    ...mapGetters("academy", {
      academies: "getAcademies",
    }),
  },
  mounted() {
    this.refresh();
  },
  methods: {
    async refresh() {
      await this.$store.dispatch("academy/getAll");
    },
    async print() {
      await this.$store.dispatch("academy/printFichePresence");
    },
    async createPresence() {
      await this.$store.dispatch("academy/createFichePresence");
    },
    async printRegistration(reference) {
      await this.$store.dispatch("customer/printRegistration", reference);
    },
    async remove(data) {
      let academy = data;
      this.$store.dispatch("academy/destroy", academy.reference);
    },
    sendWhatsAppMessage(phone, amount, name, dueDate) {
      if (!phone) {
        alert("Numéro de téléphone non disponible");
        return;
      }
      const formattedPhone = "212" + phone.slice(-9);
      const monthNames = ["يناير", "فبراير", "مارس", "أبريل", "مايو", "يونيو", "يوليوز", "غشت", "شتنبر", "أكتوبر", "نونبر", "دجنبر"];
      const currentMonth = new Date().getMonth();
      const message = `السلام عليكم،\nيرجى تسديد مبلغ ${amount} درهم الخاص بابنكم ${name} لشهر ${monthNames[currentMonth]} قبل ${dueDate}.\n\nأكاديمية كرة القدم أورينت5 بركان`;
      const url = `https://wa.me/${formattedPhone}?text=${encodeURIComponent(message)}`;
      window.open(url, "_blank");
    }
  }
};
</script>
